<template>
    <div class="flex-grow mx-4 my-3">
        <div class="mb-3 lg:mx-4">
            <h2>Opgavetyper</h2>
        </div>

        <div class="rounded-sm bg-white shadow">
            <validation-observer
                v-slot="{ handleSubmit }"
                ref="observer">
                <form
                    class="p-4 flex flex-wrap items-start"
                    @submit.prevent="handleSubmit(create)">
                    <div class="w-full py-2 lg:w-1/3 lg:p-0">
                        <v-input
                            id="newTaskType"
                            v-model="newTaskType.name"
                            rule="required"
                            placeholder="Angiv et navn"
                            name="Navn"
                            autofocus/>
                    </div>
                    <div class="w-full py-2 lg:w-1/3 lg:ml-4 lg:p-0">
                        <v-select
                            v-model="newTaskType.color"
                            rule="required"
                            name="Farve">
                            <option
                                disabled
                                :value="null">
                                Vælg en farve
                            </option>
                            <option
                                v-for="option in colorOptions"
                                :key="option.value"
                                :value="option.value">
                                {{ option.text }}
                            </option>
                        </v-select>
                    </div>
                    <button
                        type="submit"
                        class="btn --primary lg:ml-4">
                        Opret ny opgavetype
                    </button>
                </form>
            </validation-observer>

            <div class="table__header">
                <div class="w-1/3">
                    Navn
                </div>
                <div class="w-1/4">
                    Farve
                </div>
                <div class="flex-grow"></div>
                <div class="w-1/4"></div>
            </div>

            <div
                v-for="taskType in taskTypes"
                :key="taskType.id"
                class="table__row"
                :class="{ 'pl-0': edittedTaskType && edittedTaskType.id === taskType.id }">
                <div
                    v-if="edittedTaskType && edittedTaskType.id === taskType.id"
                    class="w-1/3 pr-4">
                    <input
                        v-model="editTaskType.name"
                        type="text">
                </div>
                <div
                    v-else
                    class="w-1/3">
                    {{ taskType.displayName }}
                </div>

                <div
                    v-if="edittedTaskType && edittedTaskType.id === taskType.id"
                    class="w-1/3 pl-3">
                    <select v-model="editTaskType.color">
                        <option
                            disabled
                            value>
                            Vælg en farve
                        </option>
                        <option
                            v-for="option in colorOptions"
                            :key="option.value"
                            :value="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div
                    v-else
                    class="w-1/4">
                    <div class="relative flex items-center">
                        <span
                            class="entry py-1"
                            :class="taskType.color.toLowerCase()">
                            {{ getColorName(taskType.color) }}
                        </span>
                    </div>
                </div>

                <div class="flex-grow flex items-center justify-end px-4">
                    <input
                        id="isDefaultOption"
                        v-model="taskType.isDefaultOption"
                        type="checkbox"
                        @change="makeDefaultOption(taskType)">
                    <label
                        for="isDefaultOption"
                        class="pl-2 pb-0">Standard-valg</label>
                </div>

                <div class="w-1/4 flex items-center justify-end">
                    <i
                        v-if="edittedTaskType && edittedTaskType.id === taskType.id"
                        class="material-icons icon-button mr-4"
                        @click="update(taskType)">check</i>
                    <i
                        v-if="edittedTaskType && edittedTaskType.id === taskType.id"
                        class="material-icons icon-button cursor-pointer"
                        @click="cancel">cancel</i>

                    <i
                        v-if="!edittedTaskType.id"
                        class="material-icons icon-button mr-4"
                        @click="edit(taskType)">edit</i>
                    <i
                        v-if="!edittedTaskType.id"
                        class="material-icons icon-button cursor-pointer"
                        @click="deleteTaskType(taskType.id)">delete</i>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Displayable } from '@/_typedef/common.models';
import Vue from 'vue';
import Component from 'vue-class-component';
import taskTypesService from '../core/services/tasktypes/taskTypes.service';
import { TaskTypeViewObject, Color } from '../_typedef/missile';

@Component({
    name: 'task-types'
})
export default class TaskTypes extends Vue {
    newTaskType: any = { color: '' };
    editTaskType: any = {};
    edittedTaskType: TaskTypeViewObject = {} as TaskTypeViewObject;

    async created() {
        taskTypesService.getAll();
    }

    get taskTypes(): TaskTypeViewObject[] {
        return taskTypesService.taskTypes;
    }

    edit(taskType: TaskTypeViewObject) {
        this.editTaskType.name = taskType.displayName;
        this.editTaskType.color = taskType.color;
        this.edittedTaskType = taskType;
    }

    cancel() {
        this.editTaskType = {};
        this.edittedTaskType = {} as TaskTypeViewObject;
    }

    getColorName(color: string) {
        return this.colorOptions.filter(x => x.value === color)[0].text;
    }

    async create() {
        await taskTypesService.create({
            name: this.newTaskType.name,
            color: this.newTaskType.color
        });
        this.newTaskType = { color: '' };

        (this.$refs.observer as any).reset();
    }

    async update(team: TaskTypeViewObject) {
        await taskTypesService.update(team.id, this.editTaskType);

        this.cancel();
    }

    async deleteTaskType(teamId: number) {
        if (confirm('Er du sikker?')) {
            await taskTypesService.delete(teamId);
        }
    }

    async makeDefaultOption(taskType: TaskTypeViewObject) {
        await taskTypesService.makeDefaultOption(taskType.id);
    }

    colorOptions: Displayable[] = [
        { value: Color.Gray, text: 'Grå' },
        { value: Color.Blue, text: 'Blå' },
        { value: Color.Green, text: 'Grøn' },
        { value: Color.Red, text: 'Rød' },
        { value: Color.Purple, text: 'Lilla' },
        { value: Color.Orange, text: 'Orange' },
        { value: Color.Yellow, text: 'Gul' },
        { value: Color.Pink, text: 'Pink' },
        { value: Color.Teal, text: 'Teal' },
        { value: Color.Indigo, text: 'Indigo' }
    ];
}
</script>