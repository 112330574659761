
	export interface CreateTeamRequest {
		name: string;
	}
	export interface UpdateTeamRequest extends CreateTeamRequest {
	}
	export interface TeamViewObject {
		displayName: string;
		id: number;
		isDefaultOption: boolean;
	}
	export interface CreateTaskTypeRequest {
		color: Color;
		name: string;
	}
	export interface UpdateTaskTypeRequest extends CreateTaskTypeRequest {
	}
	export interface TaskTypeViewObject {
		color: Color;
		displayName: string;
		id: number;
		isDefaultOption: boolean;
	}
	export interface CreateEntryRequest {
		comment?: string;
		free?: boolean;
		fromDate?: Date;
		recurring?: RecurringEntry;
		taskTypeId?: number;
		teamId?: number;
		toDate?: Date;
		utcOffset?: number;
	}
	export interface CsvExportRequest {
		fromDate?: Date;
		includeFree?: boolean;
		toDate?: Date;
	}
	export interface RecurringEntry {
		endDate: Date;
		interval: IntervalOption;
	}
	export interface UpdateEntryRequest {
		comment?: string;
		free?: boolean;
		fromDate?: Date;
		taskTypeId?: number;
		teamId?: number;
		toDate?: Date;
		utcOffset?: number;
	}
	export interface WeeklyCalendarRequest {
		day?: Date;
	}
	export interface CalendarViewModel {
		entries: Entry[];
		firstDayOfWeek: Date;
		meta: MetaData;
	}
	export interface MetaData {
		nextWeek: Date;
		previousWeek: Date;
		totalHours: number;
		week: Iso8601YearWeek;
	}
	export interface Iso8601YearWeek {
		week: number;
		year: number;
	}
	export interface Entry {
		comment: string;
		duration: number;
		formattedComment: string;
		free: boolean;
		fromDate: Date;
		id: number;
		recurringId: string;
		taskType: TaskType;
		taskTypeId: number;
		team: Team;
		teamId: number;
		toDate: Date;
		user: User;
		userId: number;
		utcOffset: TimeSpan;
	}
	export interface Team {
		deleted: boolean;
		displayName: string;
		id: number;
		isDefaultOption: boolean;
		user: User;
		userId: number;
	}
	export interface User {
		displayName: string;
		enabled: boolean;
		id: number;
		lastLogin: Date;
		passwordHashed: string;
		userName: string;
	}
	export const enum Color {
		Gray = "Gray",
		Blue = "Blue",
		Green = "Green",
		Red = "Red",
		Purple = "Purple",
		Orange = "Orange",
		Yellow = "Yellow",
		Pink = "Pink",
		Teal = "Teal",
		Indigo = "Indigo"
	}
	export interface TaskType {
		color: Color;
		deleted: boolean;
		displayName: string;
		id: number;
		isDefaultOption: boolean;
		user: User;
		userId: number;
	}
	export interface TimeSpan {
		days: number;
		hours: number;
		maxValue: TimeSpan;
		milliseconds: number;
		minutes: number;
		minValue: TimeSpan;
		seconds: number;
		ticks: number;
		totalDays: number;
		totalHours: number;
		totalMilliseconds: number;
		totalMinutes: number;
		totalSeconds: number;
		zero: TimeSpan;
	}
	export interface AuthenticateRequest {
		password: string;
		userName: string;
	}
	export interface RegisterRequest {
		displayName: string;
		password: string;
		userName: string;
	}
	export interface UpdateAccountRequest {
		account?: UpdateAccountModel;
		password?: UpdatePasswordModel;
	}
	export interface UpdateAccountModel {
		emailAddress: string;
		name: string;
	}
	export interface UpdatePasswordModel {
		confirmNewPassword: string;
		newPassword: string;
	}
	export interface AuthenticationViewModel {
		context: UserContext;
		token: string;
	}
	export interface UserContext {
		anonymous: UserContext;
		displayName: string;
		isAuthenticated: boolean;
		userId: number;
		userName: string;
	}
	export const enum IntervalOption {
		Weekly = "Weekly",
		BiWeekly = "BiWeekly",
		Monthly = "Monthly"
	}

